<template>
  <div class="bg_main full_h pb-5">
    <HeaderFormulir
      v-bind:date="date"
      v-bind:title="'Formulir Sanitarian-KIT'"
      :id="id"
      :type="5"
    />
    <div class="d-flex justify-center">
      <div class="side_toolbar">
        <div>
          <div class="item_toolbar" @click="setupForm(true)">
            <v-progress-circular
              v-if="loadTempo"
              indeterminate
              color="#00b4cc"
            ></v-progress-circular>
            <v-icon color="#00b4cc" v-else large
              >mdi-content-save-outline</v-icon
            >
          </div>
        </div>
      </div>
      <div style="margin-top: 50px; width: 85%" class="pb-5">
        <div class="temp_itemtab">
          <v-stepper
            v-model="step"
            elevation="0"
            flat
            class="temp_stepper_header"
          >
            <div class="d-flex">
              <div
                :class="
                  step == 1
                    ? 'active_tab px-3  mr-1'
                    : step > 4
                    ? 'mini_tab px-3 '
                    : 'header_tabs px-3  mr-1'
                "
              >
                <div :class="step == 1 ? 'number_active' : 'number_unactive'">
                  1
                </div>
                <p class="ma-0 small_txt text-center" v-if="step < 4">
                  Pengukuran Kualitas Udara
                </p>
              </div>
              <div
                :class="
                  step == 2
                    ? 'active_tab px-3  mx-1'
                    : step > 5
                    ? 'mini_tab px-3 py-5'
                    : 'header_tabs px-3  mx-1'
                "
              >
                <div :class="step == 2 ? 'number_active' : 'number_unactive'">
                  2
                </div>
                <p class="ma-0 small_txt text-center" v-if="step < 5">
                  Pengukuran Kualitas Pangan
                </p>
              </div>
              <div
                :class="
                  step == 3
                    ? 'active_tab px-3  mx-1'
                    : step > 6
                    ? 'mini_tab px-3 '
                    : 'header_tabs px-3  mx-1'
                "
              >
                <div :class="step == 3 ? 'number_active' : 'number_unactive'">
                  3
                </div>
                <p class="ma-0 small_txt text-center" v-if="step < 6">
                  Pengukuran Kualitas Air
                </p>
              </div>
              <div
                :class="
                  step == 4 ? 'active_tab px-3  mx-1' : 'header_tabs px-3  mx-1'
                "
              >
                <div :class="step == 4 ? 'number_active' : 'number_unactive'">
                  4
                </div>
                <p class="ma-0 small_txt text-center">
                  Peralatan Photometer Kimia
                </p>
              </div>
              <div
                :class="
                  step == 5
                    ? 'active_tab px-3  mx-1'
                    : step > 5
                    ? 'header_tabs px-3  mx-1'
                    : 'mini_tab px-3 '
                "
              >
                <div :class="step == 5 ? 'number_active' : 'number_unactive'">
                  5
                </div>
                <p class="ma-0 small_txt text-center" v-if="step > 4">
                  Peralatan Pendukung Pengukuran Mikrobiologi
                </p>
              </div>
              <div
                :class="
                  step == 6
                    ? 'active_tab px-3  mx-1'
                    : step > 6
                    ? 'header_tabs px-3  mx-1'
                    : 'mini_tab px-3 '
                "
              >
                <div :class="step == 6 ? 'number_active' : 'number_unactive'">
                  6
                </div>
                <p class="ma-0 small_txt text-center" v-if="step > 5">
                  Peralatan Pendukung
                </p>
              </div>
            </div>
            <!-- <div class="d-flex" style="overflow-x:auto">
              <div
                :class="
                  step == 1
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 1 ? 'number_active' : 'number_unactive'">
                  1
                </div>
                <p class="ma-0 small_txt text-center">
                  Pengukuran Kualitas Udara
                </p>
              </div>
              <div
                :class="
                  step == 2
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 2 ? 'number_active' : 'number_unactive'">
                  2
                </div>
                <p class="ma-0 small_txt text-center">
                  Pengukuran Kualitas Pangan
                </p>
              </div>
              <div
                :class="
                  step == 3
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 3 ? 'number_active' : 'number_unactive'">
                  3
                </div>
                <p class="ma-0 small_txt text-center">
                  Pengukuran Kualitas Air
                </p>
              </div>
              <div
                :class="
                  step == 4 ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 4 ? 'number_active' : 'number_unactive'">
                  4
                </div>
                <p class="ma-0 small_txt text-center">
                  Peralatan Photometer Kimia
                </p>
              </div>
              <div
                :class="
                  step == 5 ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 5 ? 'number_active' : 'number_unactive'">
                  5
                </div>
                <p class="ma-0 small_txt text-center">
                  Peralatan Pendukung Pengukuran Mikrobiologi
                </p>
              </div>
              <div
                :class="
                  step == 6 ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 6 ? 'number_active' : 'number_unactive'">
                  6
                </div>
                <p class="ma-0 small_txt text-center">
                  Peralatan Pendukung
                </p>
              </div>
            </div> -->

            <v-stepper-items>
              <!-- TAB 1 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="1" class="pa-0">
                <v-card
                  class="pa-5"
                  min-height="500px"
                  flat
                  v-if="pengukuran_kualitas_udara"
                >
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Ukur Kualitas Udara</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur Suhu
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            hide-details
                            class="ma-0 panjang-pilihan"
                            v-model="
                              pengukuran_kualitas_udara
                                .ketersediaan_alat_ukur_kualitas_udara
                                .alat_pengukuran_suhu.dataPenyusun_desc
                            "
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur Kelembaban
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            hide-details
                            class="ma-0 panjang-pilihan"
                            v-model="
                              pengukuran_kualitas_udara
                                .ketersediaan_alat_ukur_kualitas_udara
                                .alat_pengukuran_kelembaban.dataPenyusun_desc
                            "
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur kecepatan aliran udara
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            hide-details
                            class="ma-0 panjang-pilihan"
                            v-model="
                              pengukuran_kualitas_udara
                                .ketersediaan_alat_ukur_kualitas_udara
                                .alat_pengukur_kecepatan_aliran_udara
                                .dataPenyusun_desc
                            "
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur pencahayaan
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_udara
                                .ketersediaan_alat_ukur_kualitas_udara
                                .alat_pengukur_kecepatan_aliran_pencahayaan
                                .dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur Partikulat diudara
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_udara
                                .ketersediaan_alat_ukur_kualitas_udara
                                .alat_pengukur_partikulat_diudara
                                .dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur Kebisingan
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            v-model="
                              pengukuran_kualitas_udara
                                .ketersediaan_alat_ukur_kualitas_udara
                                .alat_pengukur_kebisingan.dataPenyusun_desc
                            "
                            dense
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur Jumlah kuman
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_udara
                                .ketersediaan_alat_ukur_kualitas_udara
                                .alat_pengukur_jumlah_kuman.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- TAB 2 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="2" class="pa-0">
                <v-card
                  class="pa-5"
                  min-height="500px"
                  flat
                  v-if="pengukuran_kualitas_pangan"
                >
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b
                        >Ketersediaan Reagen untuk pengukuran Parameter Kimia</b
                      >
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Methanyl Yellow
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            hide-details
                            v-model="
                              pengukuran_kualitas_pangan
                                .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                                .methanyl_yellow.dataPenyusun_desc
                            "
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Rodhamin B</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            hide-details
                            v-model="
                              pengukuran_kualitas_pangan
                                .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                                .rodhamin_b.dataPenyusun_desc
                            "
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Formaldehid</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            hide-details
                            v-model="
                              pengukuran_kualitas_pangan
                                .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                                .formaldehid.dataPenyusun_desc
                            "
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Boraks</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            hide-details
                            v-model="
                              pengukuran_kualitas_pangan
                                .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                                .boraks.dataPenyusun_desc
                            "
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Reagen untuk Parameter Mikrobiologi</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">E-Coli</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_pangan
                                .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi
                                .e_coli.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Coliform</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_pangan
                                .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi
                                .coliform.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Ukur Parameter Fisik</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur suhu
                            </p>
                          </div>
                        </v-col>
                        <!-- HANDLE -->
                        <v-col
                          cols="5"
                          v-if="
                            pengukuran_kualitas_pangan
                              .ketersediaan_alat_ukur_parameter_fisik
                              .alat_pengukur_suhu
                          "
                        >
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_pangan
                                .ketersediaan_alat_ukur_parameter_fisik
                                .alat_pengukur_suhu.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- TAB 3 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="3" class="pa-0">
                <v-card
                  class="pa-5"
                  min-height="500px"
                  flat
                  v-if="pengukuran_kualitas_air"
                >
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Ukur Parameter Fisik</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Warna (Colorimeter)
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_alat_ukur_parameter_fisik
                                .warna__colorimeter_.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Total Zat padat terlarut (TDS)
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_alat_ukur_parameter_fisik
                                .total_zat_padat_terlarut__tds_
                                .dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Kekeruhan (Turbiditymeter)
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_alat_ukur_parameter_fisik
                                .kekeruhan__turbiditymeter_.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b
                        >Ketersediaan Reagen untuk Pengukuran Parameter Kimia</b
                      >
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Arsen</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .arsen.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Flourida</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .flourida.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Nitrat (NO2)</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .nitrit_no2.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Nitrat (NO3)</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .nitrit_no3.dataPenyusun_desc
                            "
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <!-- <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Nitrat (NO3)</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group row dense hide-details class="ma-0">
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card> -->
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Sianida</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .sianida.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Alumunium</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            hide-details
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .alumunium.dataPenyusun_desc
                            "
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Besi</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .besi.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Kesadahan</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .kesadahan.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Klorida</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .klorida.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Mangan</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .mangan.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              pH meter (digital)
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .ph_meter_digital.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Seng</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .seng.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Sulfat</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .sulfat.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Tembaga</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .tembaga.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Amonia</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .amonia.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Sisa Klor</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .sisa_klor.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Total krom</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .total_krom.dataPenyusun_desc
                            "
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b
                        >Ketersediaan Reagen untuk pengukuran Parameter
                        Mikrobiologi</b
                      >
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">E-Coli</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_media_pengukuran_parameter_mikrobiologi
                                .e_coli.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Coliform</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_media_pengukuran_parameter_mikrobiologi
                                .coliform.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>
              <!-- TAB 4 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="4" class="pa-0">
                <v-card
                  class="pa-5"
                  min-height="500px"
                  flat
                  v-if="peralatan_photometer_kimia"
                >
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Photometer Kimia</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Photometer Kimia
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_photometer_kimia
                                .ketersediaan_alat_photometer_kimia
                                .photometer_kimia.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- Tab 5 -->
              <v-stepper-content step="5" class="pa-0">
                <v-card
                  class="pa-5"
                  min-height="500px"
                  flat
                  v-if="peralatan_pendukung_pengukuran_mikrobiologi"
                >
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Pendukung Pengukuran Mikrobiologi</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Inkubator</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung_pengukuran_mikrobiologi
                                .ketersediaan_alat_pendukung_pengukuran_mikrobiologi
                                .inkubator.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Colony counter digital
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung_pengukuran_mikrobiologi
                                .ketersediaan_alat_pendukung_pengukuran_mikrobiologi
                                .colony_counter_digital.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- Tab 6 -->
              <v-stepper-content step="6" class="pa-0">
                <v-card
                  class="pa-5"
                  min-height="500px"
                  flat
                  v-if="peralatan_pendukung"
                >
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Pendukung Pengukuran Mikrobiologi</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Pencacah sampel/blender
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .pencacah_sampel_blender.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Mortar dan pestel
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .mortar_dan_pestel.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Global Positioning System
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .global_positioning_system.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Coolbox</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .coolbox.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Pinset dan gunting
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .pinset_dan_gunting.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Pipet transfer/spuit
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .pipet_transfer_spuit.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Salin steril</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .salin_steril.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Timbangan digital
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .timbangan_digital.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Aquades</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .aquades.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Wadah sampel</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .wadah_sampel.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Masker</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .masker.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Kertas saring
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .kertas_saring.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Alcohol swab</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .alcohol_swab.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Corong kaca</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .corong_kaca.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Rak tabung</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .rak_tabung.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Lampu Spirtus
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .lampu_spirtus.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Sarung Tangan
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .sarung_tangan.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Botol Sampel</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .botol_sampel.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Tas Peralatan
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .tas_peralatan.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Spatula</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            v-model="
                              peralatan_pendukung
                                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                                .spatula.dataPenyusun_desc
                            "
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
        <div class="py-3 d-flex justify-center">
          <div class="d-flex align-center">
            <v-btn
              color="white"
              dark
              class="pa-3 custom_shadow rounded-lg text-capitalize"
              @click="switchStep('prev')"
              ><v-icon small color="#00b4cc"
                >mdi-arrow-left-circle-outline</v-icon
              >
              <p class="ma-0 color_default ml-2">Kembali</p></v-btn
            >
            <div class="mx-5">
              <p class="ma-0 color_default">{{ step }}/6</p>
            </div>
            <v-btn
              v-if="step != 6"
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow rounded-lg text-capitalize"
              @click="switchStep('next')"
            >
              <p class="ma-0 mr-2">Lanjut</p>
              <v-icon small>mdi-arrow-right-circle-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="step == 6"
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow rounded-lg text-capitalize"
              @click="setupForm(false)"
            >
              <p class="ma-0 mr-2">Kirim</p>
              <v-icon small>mdi-tray-arrow-up</v-icon>
            </v-btn>
          </div>
        </div>
        <div>
          <success-alert v-bind:dialogSuccess="dialogSuccess" />
          <Alert
            v-bind:dialogAlert="dialogAlert"
            v-bind:temporary="temporary"
            v-bind:body="tobeSent"
            @close="dialogAlert = false"
            @save="savePermanent()"
          />
          <v-overlay
            :absolute="true"
            color="white"
            style="height: 100vh; position: fixed"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="#00b4cc"
            ></v-progress-circular>
            <b class="color_default">Loading...</b>
          </v-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HeaderFormulir from "../../../components/Formulir/HeaderFormulir.vue";
import Swal from "sweetalert2";
import Alert from "../../../components/Formulir/Modal/alert.vue";
import SuccessAlert from "../../../components/Formulir/Modal/successAlert.vue";
export default {
  components: { HeaderFormulir, Alert, SuccessAlert },
  computed: {
    ...mapState({
      formulir_sanitarian: (state) => state.formulir.get_formulir_sanitarian,
    }),
    pengukuran_kualitas_udara() {
      let data = null;
      if (this.formulir_sanitarian) {
        data =
          this.formulir_sanitarian.data.sanitarian_kit
            .pengukuran_kualitas_udara;
      }
      return data;
    },
    pengukuran_kualitas_pangan() {
      let data = null;
      if (this.formulir_sanitarian) {
        data =
          this.formulir_sanitarian.data.sanitarian_kit
            .pengukuran_kualitas_pangan;
      }
      return data;
    },
    pengukuran_kualitas_air() {
      let data = null;
      if (this.formulir_sanitarian) {
        data =
          this.formulir_sanitarian.data.sanitarian_kit.pengukuran_kualitas_air;
      }
      return data;
    },
    peralatan_photometer_kimia() {
      let data = null;
      if (this.formulir_sanitarian) {
        data =
          this.formulir_sanitarian.data.sanitarian_kit
            .peralatan_photometer_kimia;
      }
      return data;
    },
    peralatan_pendukung_pengukuran_mikrobiologi() {
      let data = null;
      if (this.formulir_sanitarian) {
        data =
          this.formulir_sanitarian.data.sanitarian_kit
            .peralatan_pendukung_pengukuran_mikrobiologi;
      }
      return data;
    },
    peralatan_pendukung() {
      let data = null;
      if (this.formulir_sanitarian) {
        data = this.formulir_sanitarian.data.sanitarian_kit.peralatan_pendukung;
      }
      return data;
    },
  },
  name: "formulirSanitarian",
  data() {
    return {
      step: 1,
      calendar1: false,
      calendar2: false,
      calendar3: false,
      date: "",
      id: null,
      dialogSuccess: false,
      dialogAlert: false,
      temporary: true,
      loading: false,
      loadTempo: false,
      date: null,
      tobeSent: null,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getData();
  },
  methods: {
    saveFormulir(body) {
      this.tobeSent = body;
      this.temporary = false;
      this.dialogAlert = true;
    },
    getData() {
      this.loading = true;
      let body = {
        report_id: this.id,
      };
      console.log(body);
      this.$store
        .dispatch("formulir/getDataFormulirSanitarian", body)
        .then((data) => {
          console.log(data);
          // this.date = data.data.date;
          this.loading = false;
        });
    },
    switchStep(state) {
      if (state == "next") {
        if (this.step < 6) {
          this.step++;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
      } else {
        if (this.step > 1) {
          this.step--;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
      }
    },
    // update data
    temporarySave(body) {
      this.loadTempo = true;
      this.$store
        .dispatch("formulir/updateDataFormulirSanitarian", body)
        .then((data) => {
          console.log(data);
          // this.Swal("success", data.message);
          this.loadTempo = false;
          this.dialogAlert = true;
        })
        .catch((err) => {
          this.loadTempo = false;
          this.Swal("error", err.message);
          console.log(err);
        });
    },
    savePermanent() {
      this.loading = true;
      this.dialogSuccess = true;
      this.$store
        .dispatch("formulir/saveDataFormulirSanitarian", this.tobeSent)
        .then((data) => {
          this.dialogAlert = false;
          this.loading = false;
          setTimeout(() => {
            this.dialogSuccess = false;
            this.$router.push("/formulir");
          }, 1500);
          console.log(data);
        });
    },
    // SET UPPP FORMMMMM
    setupForm(draft) {
      let body = {
        report_id: this.id,
        data: [
          // OBJ 1
          {
            name: "Alat pengukuran suhu",

            value:
              this.pengukuran_kualitas_udara
                .ketersediaan_alat_ukur_kualitas_udara.alat_pengukuran_suhu
                .dataPenyusun_desc,
          },
          {
            name: "Alat pengukuran kelembaban",

            value:
              this.pengukuran_kualitas_udara
                .ketersediaan_alat_ukur_kualitas_udara
                .alat_pengukuran_kelembaban.dataPenyusun_desc,
          },
          {
            name: "Alat pengukur kecepatan aliran udara",

            value:
              this.pengukuran_kualitas_udara
                .ketersediaan_alat_ukur_kualitas_udara
                .alat_pengukur_kecepatan_aliran_udara.dataPenyusun_desc,
          },
          {
            name: "Alat pengukur kecepatan aliran pencahayaan",

            value:
              this.pengukuran_kualitas_udara
                .ketersediaan_alat_ukur_kualitas_udara
                .alat_pengukur_kecepatan_aliran_udara.dataPenyusun_desc,
          },
          {
            name: "Alat pengukur partikulat diudara",

            value:
              this.pengukuran_kualitas_udara
                .ketersediaan_alat_ukur_kualitas_udara
                .alat_pengukur_partikulat_diudara.dataPenyusun_desc,
          },
          {
            name: "Alat pengukur kebisingan",

            value:
              this.pengukuran_kualitas_udara
                .ketersediaan_alat_ukur_kualitas_udara.alat_pengukur_kebisingan
                .dataPenyusun_desc,
          },
          {
            name: "Alat pengukur jumlah kuman",

            value:
              this.pengukuran_kualitas_udara
                .ketersediaan_alat_ukur_kualitas_udara
                .alat_pengukur_jumlah_kuman.dataPenyusun_desc,
          },

          // OBJ 2
          {
            name: "Methanyl Yellow",

            value:
              this.pengukuran_kualitas_pangan
                .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                .methanyl_yellow.dataPenyusun_desc,
          },
          {
            name: "Rodhamin B",

            value:
              this.pengukuran_kualitas_pangan
                .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                .rodhamin_b.dataPenyusun_desc,
          },
          {
            name: "Formaldehid",

            value:
              this.pengukuran_kualitas_pangan
                .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                .formaldehid.dataPenyusun_desc,
          },
          {
            name: "Boraks",

            value:
              this.pengukuran_kualitas_pangan
                .ketersediaan_reagen_untuk__pengukuran_parameter_kimia.boraks
                .dataPenyusun_desc,
          },
          {
            name: "E-Coli",

            value:
              this.pengukuran_kualitas_pangan
                .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi
                .e_coli.dataPenyusun_desc,
            dataPenyusun_id:
              this.pengukuran_kualitas_pangan
                .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi
                .e_coli.dataPenyusun_id,
          },
          {
            name: "Coliform",

            value:
              this.pengukuran_kualitas_pangan
                .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi
                .coliform.dataPenyusun_desc,
            dataPenyusun_id:
              this.pengukuran_kualitas_pangan
                .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi
                .coliform.dataPenyusun_id,
          },
          {
            name: "Alat pengukur suhu",

            value:
              this.pengukuran_kualitas_pangan
                .ketersediaan_alat_ukur_parameter_fisik.alat_pengukur_suhu
                .dataPenyusun_desc,
          },

          // OBJ 3

          {
            name: "warna (colorimeter)",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_alat_ukur_parameter_fisik.warna__colorimeter_
                .dataPenyusun_desc,
          },
          {
            name: "total zat padat terlarut (tds)",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_alat_ukur_parameter_fisik
                .total_zat_padat_terlarut__tds_.dataPenyusun_desc,
          },
          {
            name: "kekeruhan (turbiditymeter)",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_alat_ukur_parameter_fisik
                .kekeruhan__turbiditymeter_.dataPenyusun_desc,
          },
          {
            name: "warna colorimeter",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_alat_ukur_parameter_fisik.warna__colorimeter_
                .dataPenyusun_desc,
          },
          {
            name: "total zat padat terlarut tds",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_alat_ukur_parameter_fisik
                .total_zat_padat_terlarut__tds_.dataPenyusun_desc,
          },
          {
            name: "kekeruhan turbiditymeter",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_alat_ukur_parameter_fisik
                .kekeruhan__turbiditymeter_.dataPenyusun_desc,
          },
          {
            name: "Arsen",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.arsen
                .dataPenyusun_desc,
          },
          {
            name: "Flourida",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.flourida
                .dataPenyusun_desc,
          },
          {
            name: "Nitrit NO2",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.nitrit_no2
                .dataPenyusun_desc,
          },
          {
            name: "Nitrit NO3",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.nitrit_no3
                .dataPenyusun_desc,
          },
          {
            name: "Sianida",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.sianida
                .dataPenyusun_desc,
          },
          {
            name: "Alumunium",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.alumunium
                .dataPenyusun_desc,
          },
          {
            name: "Besi",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.besi
                .dataPenyusun_desc,
          },
          {
            name: "Kesadahan",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.kesadahan
                .dataPenyusun_desc,
          },
          {
            name: "Klorida",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.klorida
                .dataPenyusun_desc,
          },
          {
            name: "Mangan",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.mangan
                .dataPenyusun_desc,
          },
          {
            name: "pH meter digital",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                .ph_meter_digital.dataPenyusun_desc,
          },
          {
            name: "Seng",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.seng
                .dataPenyusun_desc,
          },
          {
            name: "Sulfat",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.sulfat
                .dataPenyusun_desc,
          },
          {
            name: "Tembaga",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.tembaga
                .dataPenyusun_desc,
          },
          {
            name: "Amonia",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.amonia
                .dataPenyusun_desc,
          },
          {
            name: "Sisa klor",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.sisa_klor
                .dataPenyusun_desc,
          },
          {
            name: "Total krom",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia.total_krom
                .dataPenyusun_desc,
          },
          {
            name: "E-coli",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_media_pengukuran_parameter_mikrobiologi.e_coli
                .dataPenyusun_desc,
            dataPenyusun_id:
              this.pengukuran_kualitas_air
                .ketersediaan_media_pengukuran_parameter_mikrobiologi.e_coli
                .dataPenyusun_id,
          },
          {
            name: "Coliform",

            value:
              this.pengukuran_kualitas_air
                .ketersediaan_media_pengukuran_parameter_mikrobiologi.coliform
                .dataPenyusun_desc,
          },

          // OBJ 4

          {
            name: "Photometer Kimia",

            value:
              this.peralatan_photometer_kimia.ketersediaan_alat_photometer_kimia
                .photometer_kimia.dataPenyusun_desc,
          },
          {
            name: "Inkubator",

            value:
              this.peralatan_pendukung_pengukuran_mikrobiologi
                .ketersediaan_alat_pendukung_pengukuran_mikrobiologi.inkubator
                .dataPenyusun_desc,
          },
          {
            name: "Colony counter digital",

            value:
              this.peralatan_pendukung_pengukuran_mikrobiologi
                .ketersediaan_alat_pendukung_pengukuran_mikrobiologi
                .colony_counter_digital.dataPenyusun_desc,
          },
          {
            name: "Pencacah sampel/blender",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .pencacah_sampel_blender.dataPenyusun_desc,
          },
          {
            name: "Mortar dan pestel",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .mortar_dan_pestel.dataPenyusun_desc,
          },
          {
            name: "Global Positioning System",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .global_positioning_system.dataPenyusun_desc,
          },
          {
            name: "Coolbox",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_.coolbox
                .dataPenyusun_desc,
          },
          {
            name: "Pinset dan gunting",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .pinset_dan_gunting.dataPenyusun_desc,
          },
          {
            name: "Pipet transfer/spuit",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .pipet_transfer_spuit.dataPenyusun_desc,
          },
          {
            name: "Salin steril",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .salin_steril.dataPenyusun_desc,
          },
          {
            name: "Timbangan digital",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .timbangan_digital.dataPenyusun_desc,
          },
          {
            name: "Aquades",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_.aquades
                .dataPenyusun_desc,
          },
          {
            name: "Wadah sampel",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .wadah_sampel.dataPenyusun_desc,
          },
          {
            name: "Masker",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_.masker
                .dataPenyusun_desc,
          },
          {
            name: "Kertas saring",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .kertas_saring.dataPenyusun_desc,
          },
          {
            name: "Alcohol swab",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .alcohol_swab.dataPenyusun_desc,
          },
          {
            name: "Corong kaca",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .corong_kaca.dataPenyusun_desc,
          },
          {
            name: "Rak tabung",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .rak_tabung.dataPenyusun_desc,
          },
          {
            name: "Lampu Spirtus",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .lampu_spirtus.dataPenyusun_desc,
          },
          {
            name: "Sarung tangan",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .sarung_tangan.dataPenyusun_desc,
          },
          {
            name: "Botol sampel",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .botol_sampel.dataPenyusun_desc,
          },
          {
            name: "Tas Peralatan",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                .tas_peralatan.dataPenyusun_desc,
          },
          {
            name: "Spatula",

            value:
              this.peralatan_pendukung
                .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_.spatula
                .dataPenyusun_desc,
          },
        ],
      };
      if (draft) {
        this.temporarySave(body);
      } else {
        this.saveFormulir(body);
      }
    },

    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.custom_input {
  border-radius: 8px;
  width: 150px;
}
.custom_input::placeholder {
  font-size: small;
}
.custom_input:focus {
  outline: none;
}
.custom_long_input {
  border-radius: 8px;
  width: 100%;
}
.custom_long_input:focus {
  outline: none;
}
.custom_long_input::placeholder {
  font-size: small;
}

.custom_date {
  width: 120px;
}

.custom_date::placeholder {
  font-size: small;
}
.custom_date:focus {
  outline: none;
}
.header_tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00b4cc;
  background: #fff;
  width: 25%;
  border-radius: 10px 10px 0 0;
}
.active_tab {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00b4cc;
  width: 25%;
  color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
}
.mini_tab {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00b4cc;
  background: #fff;
  width: 6%;
  border-radius: 10px 10px 0 0;
  border: 1px solid #ececec;
  border-bottom: none;
}
.number_unactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ececec;
  color: #5d5d5d;
  font-size: small;
  margin-right: 3px;
}
.number_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #00b4cc;
  font-size: small;
  margin-right: 3px;
}
.temp_stepper_header {
  background: none !important;
  box-shadow: none !important;
}
/*
.header_tabs {
  display: flex;
  justify-content: center;
  color: #00b4cc;
  background: #fff;
  width: 312px;
  min-width: 300px;
  border-radius: 10px 10px 0 0;
}
.active_tab {
  display: flex;
  justify-content: center;
  background: #00b4cc;
  width: 312px;
  min-width: 300px;
  color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
}
.number_unactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ececec;
  color: #5d5d5d;
  font-size: small;
  margin-right: 3px;
}
.number_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #00b4cc;
  font-size: small;
  margin-right: 3px;
} */
.marlef {
  margin-right: 50px;
}
.side_toolbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99 !important;
}
.item_toolbar {
  margin: 0 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
}
</style>